<template>
  <div class="bg">
    <div id="loginDiv">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
            no-body
            class="border-0 mb-0 bg-semi-transparent">
            <b-card-header class="bg-primary">
              <div class="text-white text-center my-2">
                <h1 class="text-white">Welcome to GEM</h1>
                <p class="text-lead text-white">Unauthorized access is prohibited.</p>
              </div>
            </b-card-header>
            <b-card-body class="px-lg-5 py-lg-5">
              <div v-if="!authenticationInProgress">
                <div class="text-center text-muted mb-4">
                  <small>This application uses Microsoft's identity platform for authentication.</small>
                  <small>Use this 'Sign In' button to use your Microsoft credentials to gain access.</small>
                </div>
                <div
                  v-if="!showAccountInactive"
                  class="text-center">
                  <b-button
                    variant="info-tint-5"
                    native-type="submit"
                    class="my-4"
                    @click.prevent="newLogin()"
                    >Sign in</b-button
                  >
                </div>
                <div class="text-right text-muted mt-4">
                  <small>v1.4.2</small>
                </div>
              </div>
              <m-loader v-if="authenticationInProgress" />
              <b-row v-if="showAccountInactive">

                <b-card-body class="px-lg-5 py-lg-5">
                  <div class="text-center my-4">
                    <strong>Your account is inactive. Please contact the administrator.</strong>
                  </div>
                </b-card-body>
              </b-row>
              <b-row v-if="loginError">
                <b-card-body class="px-lg-5 py-lg-5">
                  <div class="text-center my-4">
                    <strong
                      >There was an error during the login process. Please try again. If this problem persists, please
                      contact support.</strong
                    >
                  </div>
                </b-card-body>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapMutations, mapGetters } from "vuex"
  import MLoader from "@/components/loader/MLoader.vue"

  export default {
    name: "Login",
    components: {
      MLoader,
    },

    data() {
      return {
        showAccountInactive: false,
        authenticationInProgress: false,
      }
    },

    computed: {
      ...mapGetters("auth", ["isAuthenticated", "loginError"]),
    },

    async mounted() {
      document.title = "Login to MCP CRM"
      this.checkAccount()
    },

    methods: {
      ...mapMutations("auth", ["setAccessToken"]),

      async checkAccount() {
        this.authenticationInProgress = true
        // I want to wait for 30 seconds before doing any of this and show me a counter in the console for each second
        for (let i = 2; i > 0; i--) {
          // console.log(i)
          await new Promise((resolve) => setTimeout(resolve, 1000))
        }
        const account = localStorage.getItem("loginResponse")
        // console.log("Check Account Login Response: " + account)
        if (account) {
          // console.log("Dispatch Hub Login")
          await this.$store.dispatch("auth/setAzureAccount", JSON.parse(account))
          await this.$store
            .dispatch("auth/hubLogin")
            .then(() => {
              if (!this.loginError) {
                // console.log("Hub Login Complete")
                this.$store.dispatch("auth/setAuthentication", true)
                this.reLogin()
              } else {
                // console.log("Hub Login Error")
                this.authenticationInProgress = false
              }
            })
            .catch((error) => {
              // console.log("Hub Login Error: " + error)
              this.authenticationInProgress = false
            })
        } else {
          // console.log("login")
          this.$store.dispatch("auth/setAuthentication", false)
          this.authenticationInProgress = false
        }
      },

      reLogin() {
        if (this.isAuthenticated) {
          try {
            this.$store.dispatch("auth/getToDoLists")
            this.$store.dispatch("common/getEntityList")
            this.$store.dispatch("notifications/getNotifications")
            this.$store.dispatch("pump/getPumpTypes")
            this.authenticationInProgress = false
          } catch (error) {
            // console.log("Error getting data")
          } finally {
            // console.log("Finally")
            this.$router.push({ name: "Home" })
          }
        } else {
          // console.log("Error with login")
        }
      },

      async newLogin() {
        await this.$store.dispatch("auth/msalLogin")
        this.authenticationInProgress = true
      },
    },
  }
</script>

<style scoped>
  #loginDiv {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  body,
  html {
    height: 100%;
  }

  .bg {
    /* The image used */
    background-image: url("https://gem-test.motherschoiceproducts.com/img/LoginBackground.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    margin-left: -30px;
    margin-right: -30px;

    /* Full height */
    min-height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bg-semi-transparent {
    background-color: rgba(255, 255, 255, 0.9);
  }
</style>
